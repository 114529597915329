export default {
    'form.entry_date': 'setEntryDate',
    'form.journal_type_id': function (v) {
        let d = this.journalTypes.filter(data => {
            if (data.value == v) {
                return data
            }
        });

        if (d.length && d[0].setting == 'manual') {
            this.manualSetting = true;
        } else {
            if(v){
                this.$rest.get('/api/journal-type-voucher/'+v).then(data=> {
                    this.automaticVoucherNo = data.data
                });
            }
            this.manualSetting = false;
        }
    },
    searchA(val) {
        if (!val) return;
        // if (this.isLoading) return;

        this.isLoading = true;
        this.$rest.get('/api/account-head?search=' + val)
            .then(({data}) => {
                this.entriesA = data.data
            }).catch(err => {
        }).finally(() => (this.isLoading = false))
    },
    searchAG(val) {
        if (!val) return;
        // if (this.isLoading) return;

        this.isLoading = true;
        this.$rest.get('/api/account-head?search=' + val)
            .then(({data}) => {
                this.entriesA = data.data
            }).catch(err => {
        }).finally(() => (this.isLoading = false))
    },
}
