import axios from "axios";

export default function mounted() {
    this.updateData = false;

    /** Get Account Heads **/
    this.getJournalTypes();

    // /** Get Account Heads **/
    this.getLedgerHeads();
    //
    // /** Get Account Heads **/
    this.getAccountHeads();

    this.getEdited();
    // this.automaticVoucherNo = '';

    // this.payment_mode_type.journal_type_id = '';

    // console.log(this.$route.params.id);
    //
    // /** default current date**/
    // this.setEntryDate();
}
