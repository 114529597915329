export default {
    itemsAccount() {
        return this.entriesA.map(entryA => {
            return Object.assign({}, entryA, {Name: entryA.name})
        })
    },
    // itemsLedger(i) {
    //     console.log(i);
    //     return this.entriesL.map(entryA => {
    //         return Object.assign({}, entryA, {Name: entryA.name})
    //     })
    // }

}
